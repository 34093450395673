import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCopy } from '@fortawesome/free-solid-svg-icons';
import CodeBlock from './CodeBlock';

const ChatPage = ({ user }) => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const messageInputRef = useRef(null);

  const handleSendMessage = async () => {
    if (message.trim() === '') return;

    const newChat = { sender: user.displayName, message, language: isArabic(message) ? 'ar' : 'en' };
    setChatHistory([...chatHistory, newChat]);
    setMessage('');
    messageInputRef.current.style.height = 'auto';

    setIsLoading(true);

    try {
      const response = await fetch('https://nataku.extra.codes/api/response', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message }),
      });
      const data = await response.json();
      setChatHistory([...chatHistory, newChat, { sender: 'NatakuAI', ...data, language: 'en' }]);
    } catch (error) {
      setChatHistory([...chatHistory, newChat, { sender: 'NatakuAI', message: 'Please clarify your words more for the design of the code.', language: 'en' }]);
    }

    setIsLoading(false);
  };

  const isArabic = (text) => {
    const arabicPattern = /[\u0600-\u06FF]/;
    return arabicPattern.test(text);
  };

  const copyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  return (
    <main className="chat-window">
      {chatHistory.map((chat, index) => (
        <div key={index} className={`chat-message ${chat.sender === user?.displayName ? 'user' : 'ai'}`}>
          <img src={chat.sender === user?.displayName ? user?.photoURL : 'nataku.png'} alt={chat.sender} />
          <div>
            <span>{chat.sender}</span>
            <p style={{ direction: chat.language === 'ar' ? 'rtl' : 'ltr' }}>{chat.message}</p>
            {chat.code && (
              <div className="code-block" data-code-type={chat.codeType}>
                <CodeBlock language={chat.language} value={chat.code} />
                <FontAwesomeIcon icon={faCopy} onClick={() => copyCode(chat.code)} />
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="message-input-container">
        <div className="message-input">
          <textarea
            ref={messageInputRef}
            value={user ? message : 'Please login to start your chat'}
            onChange={(e) => setMessage(e.target.value)}
            rows="1"
            placeholder={user ? 'Message NatakuAI' : ''}
            disabled={!user}
            style={{ resize: 'none', overflow: 'hidden' }}
            onInput={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
          />
          <button onClick={handleSendMessage} disabled={!user}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
    </main>
  );
};

export default ChatPage;