import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

const Sidebar = ({ isSidebarOpen, user, setUser }) => {
  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
      <div className="new-chat">
        <FontAwesomeIcon icon={faPlus} className="icon" />
        <a>New Chat</a>
      </div>
      <div className="discord-server">
        <FontAwesomeIcon icon={faDiscord} className="icon" />
        <a>Discord Server</a>
      </div>
      <div className="user-profile">
        {user ? (
          <>
            <img src={user.photoURL} alt={user.displayName} />
            <p>{user.displayName}</p>
            <button className="login-button" onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <button className="login-button" onClick={loginWithGoogle}>Login with Google</button>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;