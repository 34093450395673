import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDfW8MUWLmTe1N1dk6dw-U98GxQWrEX0iI",
  authDomain: "natakuai.firebaseapp.com",
  projectId: "natakuai",
  storageBucket: "natakuai.appspot.com",
  messagingSenderId: "1006545257603",
  appId: "1:1006545257603:web:65cdc44b664363984487b6",
  measurementId: "G-2J1K2D75GM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };