import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faBars } from '@fortawesome/free-solid-svg-icons';
import { auth } from './firebase';
import LoadingSpinner from './ChatPage/LoadingSpinner';
import Sidebar from './ChatPage/Sidebar';
import ChatPage from './ChatPage';
import './App.css';

const App = () => {
  const [theme, setTheme] = useState('dark');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }

    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setIsLoading(false);
      } else {
        setUser(null);
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme.includes('light') ? theme.replace('light', 'dark') : theme.replace('dark', 'light');
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <div className={`app ${theme}`} data-theme={theme}>
        <nav className="navbar">
          <FontAwesomeIcon icon={faBars} onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
          <h1>NatakuAI <span>1.2</span></h1>
          <FontAwesomeIcon icon={theme.includes('light') ? faSun : faMoon} onClick={toggleTheme} />
        </nav>
        <div className="content">
          <Sidebar isSidebarOpen={isSidebarOpen} user={user} setUser={setUser} />
          <Routes>
            <Route path="/" element={<ChatPage user={user} />} />
            <Route path="/something" element={<div>Yes is something</div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;